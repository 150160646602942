<template>
	<v-layout column :fill-height="fillHeight">
		<v-flex :class="backgroundTitleColor + (sticky ? ' sticky' : '')" shrink>
			<v-divider />
			<v-layout row>
				<template v-if="title">
					<v-subheader v-text="title" />
					<v-spacer />
				</template>
				<!-- @slot Use this slot header -->
				<slot name="header"></slot>
				<v-btn v-if="expandable" icon @click="expand = !expand">
					<v-icon v-if="expand">keyboard_arrow_up</v-icon>
					<v-icon v-else>keyboard_arrow_down</v-icon>
				</v-btn>
			</v-layout>
			<v-divider />
		</v-flex>
		<v-expand-transition v-if="expandable">
			<v-flex v-show="expand" :ma-3="!noMargin && !fillHeight" :pa-3="!noMargin && fillHeight" :scroll-y="fillHeight">
				<!-- @slot Use this slot content -->
				<slot></slot>
			</v-flex>
		</v-expand-transition>
		<v-flex v-else :ma-3="!noMargin" :scroll-y="fillHeight">
			<!-- @slot Use this slot content -->
			<slot></slot>
		</v-flex>
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'

/**
 * @displayName WSection
 *
 * @since 0.3.1
 */
export default {
	name: 'WSection',
	props: {
		expandable: {
			required: false,
			type: Boolean,
			default: false
		},
		fillHeight: {
			required: false,
			type: Boolean,
			default: false
		},
		/**
		 * If you don't want the content to be smaller in width than the header
		 */
		noMargin: {
			required: false,
			type: Boolean,
			default: false
		},
		/**
		 * If you want the header of the section to be sticky
		 */
		sticky: {
			required: false,
			type: Boolean,
			default: false
		},
		/**
		 * The text displayed in the header of the section
		 */
		title: {
			required: true,
			type: String
		}
	},
	data: function () {
		return {
			expand: true
		}
	},
	computed: {
		...mapState({
			dark: state => state.user.darkMode
		}),
		backgroundTitleColor: function () {
			let result = null
			if (this.dark) {
				result = 'grey darken-4'
			} else {
				result = 'grey lighten-4'
			}
			return result
		}
	}
}
</script>

<style scoped>
.sticky {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 1;
}
</style>
